
  import axios, { AxiosError } from 'axios';
  import { defineComponent, ref, onMounted, watch } from 'vue';
  import { useRouter } from "vue-router";
  import useAlert from "@/composables/Alert";
  import {
    CampHeader,
    CampEmptyListFeedbackV2,
    CampTable,
    CampTableTd,
    CampTableTh
  } from '@/components';
  import { useLoaderStore } from "@/store/LoaderStore";
  import CampModalDelRegister from '@/components/CampModalDelRegister.vue';
  import { useCompanyStore } from '@/store/CompanyStore';
  import { useFilterMainStore } from '@/store/FilterMainStore';
  import { Modal } from "bootstrap";
  import modalRelation from './Components/modalRelation.vue';


  
  export interface IRegionalManagement {
    id: any,
    name : string,
    responsible : string,
    responsible_telephone : string;
  }
  
  interface IStoreProfileResponse {
    data: {
      error: boolean,
      message: string,
      data: IRegionalManagement[]
      errorCode: string
    }
  }
  
  export default defineComponent({
    name: "RegionalManagementList",
    components: {
      CampHeader,
      CampEmptyListFeedbackV2,
      CampTable,
      CampTableTd,
      CampTableTh,
      CampModalDelRegister,
      modalRelation
    },
    setup() {
      const router = useRouter();
      const { showTimeAlert } = useAlert()
      const queryObject = ref<{
        isLoading: boolean,
        noError: boolean,
        data:  null | IRegionalManagement[]
      }>({
        isLoading: false,
        noError: false,
        data: null
      })
      const loaderStore = useLoaderStore()
      const itemDel = ref<IRegionalManagement | null>(null)
      const toggleModalDelRegister = ref(false)
      const printGroups = ref<null | IRegionalManagement[]>(null)
      const keywordSearch = ref("")
      const isLoading = ref(false)
      const companyStore = useCompanyStore()
      const filterMainStore = useFilterMainStore()
      let auxModal;
      const relationProps = ref(false)
      const relationData = ref<any | null>(null)

  
      // Functions
      async function handleGetProfiles() {
        isLoading.value = true
        try {
          const result: IStoreProfileResponse = await axios.get(`/api/getManagementGroup`)
          const { data } = result
          queryObject.value.data = data.data
          printGroups.value = data.data
        } catch (error) {
          if(error instanceof AxiosError) {
              showTimeAlert(error.response?.data.message, "error")
          } else {
            showTimeAlert("Algo deu errado!", "error")
          }
        } finally {
          loaderStore.close()
          isLoading.value = false
        }
      }
  
      function handleFilterObjectsByKeyword(): boolean {
        if(!queryObject.value.data)
          return true
        printGroups.value = queryObject.value.data.filter((item) => {
          for (const key in item) {
            if (key !== 'checked') {
              const propValue = item[key];
              if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
                return true;
              }
              if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
                return true;
              }
            }
          }
          return false;
        });
        return false
      }
  
      function handleModalDelRegister(item: IRegionalManagement) {
        toggleModalDelRegister.value = !toggleModalDelRegister.value
        itemDel.value = item
      }
  
      async function handleConfirmItemDel() {
        if(itemDel.value) {
          try {
            loaderStore.open()
            const result = await axios.delete(`/api/deleteManagementGroup/${itemDel.value.id}`)
            showTimeAlert(`O grupo ${itemDel.value.name} foi excluído com sucesso!`)
            handleGetProfiles()
            itemDel.value = null
          } catch (error) {
            if(error instanceof AxiosError) {
                showTimeAlert(error.response?.data.message, "error")
            } else {
              showTimeAlert("Algo deu errado!", "error")
            }
          } finally {
            loaderStore.close()
          }
        }
      }
  
      function handleInitFilterModal() {
        if(!companyStore.getId) {
          filterMainStore.hideModal()
          filterMainStore.showModal()
        }
      }

      const openModal = id => {
        const auxElement = document.querySelector(`#${id}`);
        auxModal = new Modal(auxElement);
        auxModal.show();
      };

      function redirectToStoreProducts(data) {
        openModal('myModal')
        relationProps.value = !relationProps.value
        relationData.value = data
    }
  
      // Life Cycles
      onMounted(async () => (handleGetProfiles(), handleInitFilterModal()))
  
      watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())
  
      watch(() => companyStore.id, () => handleGetProfiles(), {deep: true})
  
      return {
        router,
        keywordSearch,
        printGroups,
        isLoading,
        queryObject,
        handleModalDelRegister,
        toggleModalDelRegister,
        itemDel,
        handleConfirmItemDel,
        redirectToStoreProducts,
        relationData,
        relationProps
      }
    }
  })
  